<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { Navigation } from '@/router/navigation';
    import { RouterLink } from 'vue-router';
    import MarketLanguageSelect from '@/components/navigation/MarketLanguageSelect.vue';
    import NavLogo from '@/components/navigation/NavLogo.vue';
    import AccountButton from '@/components/AccountButton.vue';
    import CartButton from '@/components/cart/CartButton.vue';
    import { isMobile } from '@/util/breakpoints';
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    import PanelMenu from 'primevue/panelmenu';
    import CatalogSettings from '@/components/catalog-settings/CatalogSettings.vue';
    import NavBarVerticalDivider from '@/components/navigation/NavBarVerticalDivider.vue';
    import NavAreaSelect from '@/components/navigation/NavAreaSelect.vue';
    import { useNavigationItemsQuery } from '@/composables/navigation-items';
    import { NavArea } from '@/types/NavArea';
    import Badge from 'primevue/badge';

    const showMobileDialog = ref(false);

    const { activeArea, navAreas } = useNavigationItemsQuery();

    const navBarItems = computed(() => navAreas.value[activeArea.value].navBarItems);

    function hideMobileDialog(): void {
        showMobileDialog.value = false;
    }
</script>

<template>
    <template v-if="isMobile">
        <div class="nav-bar">
            <div class="nav-bar-top nav-bar-mobile">
                <div class="nav-bar-mobile-left-container">
                    <Button icon="pi pi-bars" @click="showMobileDialog = true"></Button>
                    <RouterLink class="logo-link" :to="{ name: Navigation.Landing }">
                        <NavLogo is-mobile />
                    </RouterLink>
                </div>
                <div class="right">
                    <AccountButton />
                    <CartButton v-if="activeArea !== NavArea.MY_AREA" />
                </div>
            </div>
        </div>
        <Dialog
            v-model:visible="showMobileDialog"
            modal
            :show-header="false"
            class="p-dialog-maximized"
            :pt="{
                content: { style: 'padding: 0;' },
            }"
        >
            <div class="nav-dialog-container">
                <div class="dialog-header">
                    <Button
                        text
                        rounded
                        class="close-button"
                        icon="pi pi-times"
                        severity="secondary"
                        @click="hideMobileDialog()"
                    />
                    <NavLogo is-mobile />
                </div>
                <div class="dialog-content">
                    <NavAreaSelect is-mobile @close="hideMobileDialog()" />
                    <div v-if="navBarItems.length > 0" class="mobile-divider" />
                    <PanelMenu id="mobile-nav-bar-panel-menu" :model="navBarItems">
                        <template #item="{ item, active }">
                            <div class="mobile-panel-item text-base-semibold-line-height-auto">
                                <router-link :to="{ name: item.route }" class="link-reset" @click="hideMobileDialog()">
                                    <span v-if="item.icon" :class="item.icon" />
                                    <span>{{ item.label }}</span>
                                    <span v-if="item.count != null" class="lead-badge">
                                        <Badge :value="item.count" severity="danger" />
                                    </span>
                                </router-link>
                                <span
                                    v-if="item.items"
                                    class="right pi"
                                    :class="active ? 'pi-angle-up' : 'pi-angle-down'"
                                />
                            </div>
                        </template>
                    </PanelMenu>
                    <div class="mobile-divider" />
                    <div class="mobile-catalog-settings-container">
                        <CatalogSettings v-if="activeArea !== NavArea.MY_AREA" />
                    </div>
                    <div v-if="activeArea !== NavArea.MY_AREA" class="mobile-divider" />
                    <div class="account-cart" @click="hideMobileDialog()">
                        <CartButton v-if="activeArea !== NavArea.MY_AREA" in-mobile-nav />
                    </div>
                </div>
                <div class="dialog-footer">
                    <MarketLanguageSelect in-mobile-nav />
                </div>
            </div>
        </Dialog>
    </template>
    <template v-else>
        <div class="nav-bar">
            <div class="nav-bar-top-color-container">
                <div class="nav-bar-top max-width-container">
                    <RouterLink class="logo-link" :to="{ name: Navigation.Landing }">
                        <NavLogo />
                    </RouterLink>
                    <NavAreaSelect />
                    <div class="right">
                        <MarketLanguageSelect />
                    </div>
                </div>
            </div>
            <div class="nav-bar-bottom-color-container">
                <div class="nav-bar-bottom max-width-container">
                    <div class="nav-bar-bottom-items">
                        <template v-for="menuItem in navBarItems" :key="menuItem.label">
                            <router-link
                                :to="{ name: menuItem.route }"
                                class="nav-bar-bottom-item text-base-semibold-line-height-auto"
                            >
                                {{ menuItem.label }}
                                <span v-if="menuItem.count != null && menuItem.count > 0" class="lead-badge">
                                    <Badge :value="menuItem.count" severity="danger" />
                                </span>
                            </router-link>
                        </template>
                    </div>
                    <div class="right">
                        <CatalogSettings v-if="activeArea !== NavArea.MY_AREA" />
                        <NavBarVerticalDivider v-if="activeArea !== NavArea.MY_AREA" />
                        <AccountButton />
                        <CartButton v-if="activeArea !== NavArea.MY_AREA" />
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .link-reset {
        text-decoration: none;
        color: main.$color-text;
    }

    .mobile-catalog-settings-container {
        padding: 0 main.$spacing-4;
    }

    .close-button {
        color: main.$color-white;

        &:hover {
            background-color: main.$color-primary-600;
        }
    }

    .account-cart {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
        padding: main.$spacing-3 0;
    }

    .mobile-panel-item {
        display: flex;
        row-gap: main.$spacing-3;
        align-items: center;
        cursor: pointer;
        padding: main.$spacing-4;
        background: main.$color-white;
        color: main.$color-text;

        &:hover {
            background-color: main.$color-background-bluegray;
        }
    }

    :deep(.p-toggleable-content) .mobile-panel-item {
        padding-left: main.$spacing-6;
    }

    :deep(.p-panelmenu) {
        .p-panelmenu-content,
        .p-panelmenu-header-content {
            border: none;
        }
    }

    .nav-dialog-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .dialog-header {
        width: 100%;
        height: 56px;
        min-height: 56px;
        display: flex;
        align-items: center;
        gap: main.$spacing-5;
        padding: main.$spacing-3 main.$spacing-5;
        background: main.$color-primary-500;
        color: main.$color-text;
        border-bottom: 1px solid main.$color-border-gray;

        .pi {
            width: 40px;
            text-align: center;
        }
    }

    .dialog-content {
        padding: main.$spacing-3 main.$spacing-5;
    }

    .panel-item {
        cursor: pointer;

        .pi {
            padding-right: main.$spacing-3;
        }
    }

    .nav-bar {
        background: main.$color-primary-500;
        color: main.$color-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mobile-divider {
        border-top: 1px solid main.$color-border-gray;
        padding-top: main.$spacing-3;
        margin-top: main.$spacing-3;
    }

    .nav-bar-top-color-container {
        @include main.apply-scrollbar-spacing;

        background: main.$horizontal-menu-background-darker-color;
    }

    .nav-bar-bottom-color-container {
        @include main.apply-scrollbar-spacing;
    }

    .nav-bar-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: main.$spacing-7;
        height: 44px;
    }

    .logo-link {
        padding: main.$spacing-3 0;
        line-height: 1;
    }

    .nav-bar-bottom {
        display: flex;
        flex-direction: row;
        height: 60px;
    }

    .nav-bar-bottom-items {
        display: flex;
        align-items: center;
        gap: main.$spacing-6;
    }

    .nav-bar-bottom-item {
        text-decoration: none;
        color: main.$color-white;
        padding: main.$spacing-4;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .nav-bar-bottom-item:hover {
        background: main.$color-primary-600;
    }

    .nav-bar-mobile {
        padding: main.$spacing-3 main.$spacing-4;
        height: main.$spacing-height-nav-bar;
    }

    .nav-bar-mobile-left-container {
        display: flex;
        gap: main.$spacing-3;
    }

    .right {
        display: flex;
        margin-left: auto;
    }

    .dialog-footer {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        border-top: 1px solid main.$color-border-gray;
    }

    .lead-badge {
        margin-left: main.$spacing-3;
    }
</style>
