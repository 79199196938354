<script setup lang="ts">
    import { useCartQuery } from '@/composables/cart';
    import router from '@/router';
    import { getComputedCartUrlByProvisionType } from '@/util/router';
    import Button from 'primevue/button';
    import OverlayBadge from 'primevue/overlaybadge';
    import { useI18n } from 'vue-i18n';
    import { componentStyle } from '@/styling/util/component-style.ts';

    withDefaults(
        defineProps<{
            inMobileNav?: boolean;
        }>(),
        {
            inMobileNav: false,
        }
    );

    const cartQuery = useCartQuery();
    const { t } = useI18n();

    const cartUrl = getComputedCartUrlByProvisionType();

    const overlayBadgeStyle = componentStyle({
        outlineWidth: '0',
        warnBackground: 'rgba(255, 221, 0, 1)',
        warnColor: '{ primary.500 }',
    });
</script>

<template>
    <Button
        v-if="inMobileNav && cartUrl != null"
        :label="t('CART.CART')"
        icon="pi pi-shopping-cart"
        text
        class="mobile-button text-base-semibold-line-height-auto"
        @click="router.push({ name: cartUrl })"
    />
    <Button v-else-if="cartUrl != null" data-testid="navbar-cart-button" @click="router.push({ name: cartUrl })">
        <OverlayBadge
            v-if="cartQuery.lineItemsQuantityCount.value > 0"
            :value="cartQuery.lineItemsQuantityCount.value"
            :dt="overlayBadgeStyle"
            severity="warn"
        >
            <i class="pi pi-shopping-cart" />
        </OverlayBadge>
        <i v-else class="pi pi-shopping-cart" />
    </Button>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    :deep(.p-badge.p-badge-warning) {
        padding: 0;
        border-radius: 50%;
        min-width: main.$spacing-5;
        height: main.$spacing-5;
    }

    .mobile-button {
        color: main.$color-text;
        text-align: left;
        justify-content: left;
        width: 100%;
        padding: main.$spacing-4;
        border-radius: 0;
    }
</style>
