<script setup lang="ts">
    import Dialog from 'primevue/dialog';
    import { useMarketQuery } from '@/composables/market';
    import { useConfirm } from 'primevue/useconfirm';
    import router from '@/router';
    import { MARKET_SWITCH_CONFIRMATION_DIALOG } from '@/constants';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { useI18n } from 'vue-i18n';
    import { computed, ref } from 'vue';
    import Button from 'primevue/button';
    import { useRoute } from 'vue-router';
    import { replacePathParams, stringValueOfParam } from '@/util/param';
    import { useAuthenticationQuery } from '@/composables/authentication';
    import { languageCodeToName } from '@/util/language-code-to-name';
    import { useLanguage } from '@/composables/useLanguage';

    withDefaults(
        defineProps<{
            inMobileNav?: boolean;
        }>(),
        {
            inMobileNav: false,
        }
    );

    const { t } = useI18n();
    const route = useRoute();
    const confirm = useConfirm();
    const { availableLanguages } = useLanguage();

    const { market, availableMarkets } = useMarketQuery();
    const { cart, lineItemsQuantityCount } = useCartQuery();
    const { isUserLoggedIn } = useAuthenticationQuery();

    const cartAction = useCartAction();

    const showDialog = ref(false);

    const selectedLanguage = computed(() => stringValueOfParam(route.params.language));

    async function handleChangeLanguage(language: string): Promise<void> {
        const path = replacePathParams(route.path, { language });

        showDialog.value = false;

        await router.push({ path });
    }

    async function handleChangeMarket(newMarketCode: string): Promise<void> {
        if (market.value?.code === newMarketCode) {
            showDialog.value = false;
            return;
        }

        const confirmed = await new Promise<boolean>((resolve) => {
            if (cart.value == null) {
                resolve(true);
            } else {
                const baseMessage = isUserLoggedIn.value
                    ? t('MARKET.CHANGE_MARKET_CONFIRM_DIALOG.MESSAGE_LOGGED_IN')
                    : t('MARKET.CHANGE_MARKET_CONFIRM_DIALOG.MESSAGE_LOGGED_OUT');

                confirm.require({
                    group: MARKET_SWITCH_CONFIRMATION_DIALOG,
                    message: `${lineItemsQuantityCount.value > 0 ? `${t('MARKET.CHANGE_MARKET_CONFIRM_DIALOG.MESSAGE_CART_WITH_ITEMS_PREFIX')} ` : ''}${baseMessage}`,
                    header: t('MARKET.CHANGE_MARKET_CONFIRM_DIALOG.HEADER'),
                    acceptClass: 'p-button p-component',
                    rejectClass: 'p-button p-component p-button-outlined',
                    rejectLabel: t('MARKET.CHANGE_MARKET_CONFIRM_DIALOG.REJECT_LABEL'),
                    acceptLabel: t('MARKET.CHANGE_MARKET_CONFIRM_DIALOG.ACCEPT_LABEL'),
                    reject: () => resolve(false),
                    accept: () => resolve(true),
                });
            }
        });

        if (confirmed && isUserLoggedIn.value) {
            await setMarketAndReload(newMarketCode);
            cartAction.resetAllCartStores();
            await cartAction.initCartStore();
            await cartAction.retrieveCart();
        } else if (confirmed && !isUserLoggedIn.value) {
            cartAction.resetAllCartStores();
            await setMarketAndReload(newMarketCode);
            await cartAction.retrieveCart();
        }
    }

    async function setMarketAndReload(code: string): Promise<void> {
        const path = replacePathParams(route.path, { market: code });

        await router.push({ path });

        router.go(0);
    }
</script>

<template>
    <template v-if="market">
        <Button
            text
            class="select-button"
            :class="{ 'mobile-nav-button': inMobileNav }"
            data-testid="language-market-select-button"
            :icon="`fi fis fi-${market.code.toLowerCase()}`"
            icon-class="market-language-select-icon"
            :label="`${t('MARKET.' + market.code.toUpperCase())} / ${languageCodeToName(selectedLanguage)}`"
            @click="showDialog = true"
        />

        <Dialog
            id="market-language-select-dialog"
            v-model:visible="showDialog"
            modal
            dismissable-mask
            :draggable="false"
            :header="t('NAVIGATION.MARKET_LANGUAGE_SELECT')"
            content-class="market-language-select-dialog-content color-text"
        >
            <h3 class="text-base-bold-line-height-auto title">{{ t('NAVIGATION.MARKET_TITLE') }}</h3>
            <div class="market-language-dialog-container">
                <div
                    v-for="availableMarket in availableMarkets"
                    :key="availableMarket.code"
                    class="dialog-item"
                    :class="{ selected: availableMarket.code === market.code }"
                    @click="handleChangeMarket(availableMarket.code)"
                >
                    <span :class="`fi fis market-language-select-icon fi-${availableMarket.code.toLowerCase()}`"></span>
                    <span class="text-base-semibold-line-height-auto">
                        {{ t('MARKET.' + availableMarket.code.toUpperCase()) }}
                    </span>
                </div>
            </div>
            <h3 class="text-base-bold-line-height-auto title">{{ t('NAVIGATION.LANGUAGE_TITLE') }}</h3>
            <div class="market-language-dialog-container">
                <div
                    v-for="language in availableLanguages"
                    :key="language"
                    class="dialog-item"
                    :class="{ selected: language === selectedLanguage }"
                    @click="handleChangeLanguage(language)"
                >
                    <span class="text-base-semibold-line-height-auto">{{ languageCodeToName(language) }}</span>
                </div>
            </div>
        </Dialog>
    </template>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .select-button {
        background: transparent;
        color: main.$color-white;
        box-shadow: none;

        &:hover {
            background: main.$color-primary-500;
        }
    }

    .mobile-nav-button {
        color: main.$color-text;
        background: main.$color-background-lightgray;
        height: 48px;
        justify-content: left;

        .pi-angle-right {
            margin-left: auto;
        }

        &:hover {
            background: main.$color-background-lightgray;
            opacity: 85%;
        }
    }

    .select-button-container {
        display: flex;
        gap: main.$spacing-3;
        align-items: center;
        width: 100%;
    }

    .market-language-dialog-container {
        column-count: 1;
        column-gap: main.$spacing-6;
        padding: main.$spacing-3 0;
    }

    .title {
        padding-top: main.$spacing-5;
        padding-bottom: main.$spacing-4;
    }

    .dialog-item {
        display: flex;
        align-items: center;
        padding: main.$spacing-4 main.$spacing-5;
        line-height: 1;
        text-transform: capitalize;
        gap: main.$spacing-3;
        cursor: pointer;

        &:hover {
            background-color: main.$color-background-bluegray;
        }

        &.selected {
            background: main.$color-background-lightblue;
        }
    }
</style>
